<template>
  <div id="craigslist-account-list">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1 class="title">
        Craigslist Accounts
      </h1>
      <el-button
        icon="el-icon-plus"
        type="primary"
        size="medium"
        @click="goToAccountAdd()"
      >
        Add Account
      </el-button>
    </el-row>
    <async-paged-table
      :loading="loading"
      :data="accounts"
      :total="total"
      :table-attrs="{ 'height': 'calc(100vh - 294px)' }"
      @fetch="fetchData"
    >
      <el-table-column label="Email" prop="email">
        <template slot-scope="scope">
          <link-button
            :to="{ name: 'CraigslistAccountEdit', params: { email: scope.row.email } }"
            type="primary"
          >
            {{ scope.row.email }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column label="Region" prop="region" />
      <el-table-column label="Status" prop="status" />
    </async-paged-table>
  </div>
</template>

<script>
import AsyncPagedTable from '@/components/tables/AsyncPagedTable'
import LinkButton from '@/components/buttons/LinkButton'

import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CraigslistAccountList',
  components: {
    'async-paged-table': AsyncPagedTable,
    'link-button': LinkButton
  },
  data () {
    return {
      loading: false,
      accounts: [],
      total: 0
    }
  },
  methods: {
    /**
     * Fetch craigslist account data from the api.
     *
     * @param {Object} params - query params (page_size, page, search)
     */
    async fetchData (params) {
      try {
        this.loading = true
        const response = await CraigslistAPI.accounts.listPage(params)
        this.accounts = response.data
        this.total = response.count
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Navigates user to the CraigslistAccountAdd view.
     */
    goToAccountAdd () {
      this.$router.push({ name: 'CraigslistAccountAdd' })
    }
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font-size: 2rem;
}
</style>
